import {RECEIVE_WHISKIES, REQUEST_WHISKIES} from "../actions/whiskey";

const defaultState = {
  isFetching: false,
  items: []
};

export const reducer = function (state = defaultState, action) {
  switch (action.type) {
    case REQUEST_WHISKIES:
      return Object.assign({}, state, {
        isFetching: true
      })
    case RECEIVE_WHISKIES:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.payload
      })
    default:
      return state;
  }
};
