import React from 'react';
import './whiskey-selection.scss';
import Card from './../card/card'
import Filter from './../filter/filter';
import {connect} from "react-redux";

class WhiskeySelection extends React.Component {

    render() {
        const categories = this.props.whiskies.map((data, index) =>
            <Card key={index} uri={data.uri} title={data.title} region={data.region} cost={data.cost} tasting_notes={data.tasting_notes} image={data.image}/>
        );

        return (
            <section className="section whiskey-selection">
                <h1 className="whiskey-selection__title title title--page">Whiskey Selection</h1>
                <div className="container">

                    <Filter
                        categories={["all", "campbeltown", "highlands", "islands", "islay", "lowlands", "speyside"]}/>

                    <div className="whiskey-selection__cards">
                        {categories}
                    </div>

                </div>
            </section>
        )
    }
}

const mapStateToProps = function (store) {
    return {
        whiskies: store.whiskey.items
    };
};

export default connect(mapStateToProps, null)(WhiskeySelection);