import React from 'react';
import './article.scss';
import {connect} from "react-redux";
import Article from "./article";

class ArticleList extends React.Component {
    render() {
        const articles = this.props.articles.map((data, index) =>
            <Article key={index} title={data.title} teaser={data.teaser} img={data.img} url={data.url}/>
        );

        return (
            <section className="section article">
                <div className="container">
                    {articles}
                </div>
            </section>
        )
    }
}

const mapStateToProps = function (store) {
    return {
        articles: store.article.items
    };
};

export default connect(mapStateToProps, null)(ArticleList);