import {RECEIVE_ARTICLES, REQUEST_ARTICLES} from "../actions/article";

const defaultState = {
  isFetching: false,
  items: []
};

export const reducer = function (state = defaultState, action) {
  switch (action.type) {
    case REQUEST_ARTICLES:
      return Object.assign({}, state, {
        isFetching: true
      })
    case RECEIVE_ARTICLES:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.payload
      })
    default:
      return state;
  }
};
