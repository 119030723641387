import axios from 'axios';

export const REQUEST_ARTICLES = 'REQUEST_ARTICLES'
export const RECEIVE_ARTICLES = 'RECEIVE_ARTICLES'

function requestArticles() {
    return {
        type: REQUEST_ARTICLES,
    };
}

function receiveArticles(data) {
    return {
        type: RECEIVE_ARTICLES,
        payload: data
    }
}

function shouldFetch(state) {
    return !(state.article.isFetching || state.article.items.length);
}

function doFetch() {
    return function (dispatch) {
        dispatch(requestArticles());
        return axios.get('/data/articles.json').then(response => {
            if(response.status === 200) {
                dispatch(receiveArticles(response.data));
            }
        })
    };
}

export function fetchArticles() {
    return (dispatch, getState) => {
        if (shouldFetch(getState())) {
            // Dispatch a thunk from thunk
            return dispatch(doFetch())
        } else {
            // Let the calling code know there's nothing to wait for.
            return Promise.resolve()
        }
    }
}