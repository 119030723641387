import axios from 'axios';

export const REQUEST_WHISKIES = 'REQUEST_WHISKIES'
export const RECEIVE_WHISKIES = 'RECEIVE_WHISKIES'

function requestWhiskies() {
    return {
        type: REQUEST_WHISKIES,
    };
}

function receiveWhiskies(data) {
    return {
        type: RECEIVE_WHISKIES,
        payload: data
    }
}

function shouldFetch(state) {
    return !(state.whiskey.isFetching || state.whiskey.items.length);
}

function doFetch() {
    return function (dispatch) {
        dispatch(requestWhiskies());
        return axios.get('/data/whiskies.json').then(response => {
            if(response.status === 200) {
                dispatch(receiveWhiskies(response.data));
            }
        })
    };
}

export function fetchWhiskies() {
    return (dispatch, getState) => {
        if (shouldFetch(getState())) {
            // Dispatch a thunk from thunk
            return dispatch(doFetch())
        } else {
            // Let the calling code know there's nothing to wait for.
            return Promise.resolve()
        }
    }
}