import React from 'react';
import ArticleList from './../article/articleList';
import WhiskeySelection from './../whiskey-selection/whiskey-selection';
import './app.scss';

class App extends React.Component {
    render() {
        return (
            <div className="app">
                <WhiskeySelection/>
                <ArticleList/>
            </div>
        )
    }
}

export default App;
