import { combineReducers } from 'redux';
import { reducer as whiskeyReducer } from './whiskey';
import { reducer as articleReducer } from './article';
import { reducer as appReducer } from './app';

export default combineReducers({
  whiskey: whiskeyReducer,
  article: articleReducer,
  app: appReducer,
});
