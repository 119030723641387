import React from 'react';
import './article.scss';

class Article extends React.Component {
    render() {
        return (
            <a href={this.props.url} className="article__container" target="_blank" rel="external noreferrer noopener">
                <img className="article__image" src={require(`../../asset/image/${this.props.img}`)} alt={`${this.props.title} image`}/>
                <div className="article__content">
                    <h2 className="title--display">{this.props.title}</h2>
                    <p>{this.props.teaser}</p>
                </div>
            </a>
        )
    }
}

export default Article;