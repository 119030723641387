import React from 'react';
import './card.scss';
import { ReactComponent as CardBackground } from './../../asset/icon/card-bg.svg';
import { connect } from "react-redux";

class Card extends React.Component {

    render() {
        const tastingNotes = this.props.tasting_notes.map((note, index) =>
            <li className="card__content-taste" key={index}>{note}</li>
        );
        return (
            <a href={this.props.uri} className={`card ${this.props.region} ${this.props.filter === 'all' || this.props.filter === this.props.region ? '' : 'visually-hidden'}`}>
                <div className="card__background">
                    <CardBackground className="card__background-source"/>
                </div>
                <div className="card__content">
                    <h2 className="card__content-title title title--headline">{this.props.title}</h2>
                    <p className="card__content-region">{this.props.region} Region</p>
                    <span className="card__content-price">${this.props.cost}</span>
                    <ul className="card__content-taste-group">
                        {tastingNotes}
                    </ul>
                </div>
                <div className="card__image">
                    <img className="card__image-source" src={require(`../../asset/image/${this.props.image}`)} alt={`${this.props.title} image`}/>
                </div>
            </a>
        )
    }
}

const mapStateToProps = function (store) {
    return {
        filter: store.app.filter
    };
};

export default connect(mapStateToProps, null)(Card);