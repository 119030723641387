import {SET_FILTER} from "../actions/app";

const defaultState = {
    filter: 'all'
};

export const reducer = function (state = defaultState, action) {
    switch (action.type) {
        case SET_FILTER:
            return Object.assign({}, state, {
                filter: action.payload,
            });
        default:
            return state;
    }
};
