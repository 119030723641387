import React from 'react';
import { connect } from 'react-redux';
import { setFilter } from '../../store/actions/app'
import './filter.scss';

class Filter extends React.Component {
    render() {
        const categories = this.props.categories.map((category, index) =>
            <button className={`filter__button ${this.props.filter === category ? 'active' : ''}`}
                    onClick={() => this.props.setFilter(category)}
                    key={index}>{category === "all" ? "Show all" : category}</button>
        );
        return (
            <div className="filter">
                {categories}
            </div>
        )
    }
}

const mapDispatchToProps = {
    setFilter,
};

const mapStateToProps = function (store) {
    return {
        filter: store.app.filter
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);